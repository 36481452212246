#innaz { 
    padding-top: 25vh;
    background-image: url('https://cdn.zeplin.io/6061ed0fe3392716f0cc504a/assets/D6BDFEF7-197E-4AB4-8074-E9BADEC94602.svg');
    background-position: left top;
    background-size: cover; 
    background-size: 105vw; 
    background-repeat: no-repeat;   
    background-position-y: -110px;
}


@media only screen and (max-width: 600px) {
    #innaz {
        padding-left: 20px;
        padding-Right: 20px;
        background-size: 135vh;     
    }
    #innazMainH {
        font-size: 24px;
    }
}