#rapido { 
    padding-top: 25vh;
    background-image: url('https://cdn.zeplin.io/6061ed0fe3392716f0cc504a/assets/3ED0F65C-8235-4544-9C9A-FE45D427E6F0.svg');
    background-position: left top;
    background-size: cover; 
    background-size: 105vw; 
    background-repeat: no-repeat;   
    background-position-y: -210px;
}

.rapidoH {
    font-family: serif;
    font-size: 36px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: normal;
    color: #15204e; 
}

.rapidoHp {
    font-family: Raleway;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    color: #585757;
    text-align: left;
}

  

@media only screen and (max-width: 600px) {

    #rapido-logo {
        width: 110px !important;
    }
    #rapidoMainH {
        font-size: 24px !important;
        font-family:serif !important;
    }
    #rapido {    
        background-size: 135vh;      
    }
    .rapidoM {
      padding-left: 20px;
      padding-Right: 20px;      
  }
    #bnwimg {
        margin-left: auto !important;
        margin-top: 30px !important;
        width:85% !important;
    }
    
  .showOnMobile {
    display: block !important;
  }
  .showOnDesktop {
    display: none;
  }
   }

   .ulRapido {
    list-style: none; /* Remove default bullets */
  }
  
  .ulRapido li::before {
    content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
    color: #f6d70f !important; /* Change the color */
    font-weight: bold; /* If you want it to be bold */
    display: inline-block; /* Needed to add space between the bullet and the text */
    width: 10px; /* Also needed for space (tweak if needed) */
    margin-left: -1em; /* Also needed for space (tweak if needed) */
    font-size:23px;
  }