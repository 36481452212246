#home {   
    height:95vh;
    padding-left:20px;
    padding-right:20px;
    padding-top: 33vh;
    padding-bottom:35vh;
    font-family: Palatino-Roman; 
    color: #796b6d; 
    background-image: url('https://cdn.zeplin.io/6061ed0fe3392716f0cc504a/assets/BAA6C00D-05CF-4C28-8831-59E04D89969B.svg');
    background-position: center bottom;
    background-size: 120vw, 100vh; 
    background-repeat: no-repeat;  
}

#homeAbout {
    color: #796b6d;
    font-family: RalewayRoman;
}


@media only screen and (max-width: 880px) {
    #home {    
    background-position: center top;
    background-size: 160vh, 100vh; 
    background-position-y: 0vh;
    }
    .home-title {
        font-size: 24px !important;
        margin-bottom: 24px !important;
        font-family: serif !important;
    }
    #home-about {
        font-size: 18px !important;
    }
}