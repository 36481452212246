#navbar {
   padding-top: 15px; 
   padding-bottom: 10px; 
   margin: 0px;
   position: fixed;
   top: 0;
   width: 100vw;
   z-index: 999;
   height: auto;
}

@media only screen and (max-width: 880px) {
#logo {
   width: 51px !important;
}
}