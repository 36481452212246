#ving { 
    padding-top: 25vh;
    background-image: url('https://cdn.zeplin.io/6061ed0fe3392716f0cc504a/assets/79E13BAF-494A-45FA-B75F-A1B739A8603F.svg'),url("https://cdn.zeplin.io/6061ed0fe3392716f0cc504a/assets/12C3131B-EE0F-4746-8EEC-384E70428488.svg");
    background-position: left top;
    background-size: cover; 
    background-size: 105vw; 
    background-repeat: no-repeat;   
    background-position-y: -110px;
}

.vingH {
    font-family: serif;
    font-size: 36px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: normal;
    color: #15204e;  
}

.vingHp {
    font-family: Raleway;
    font-size: 19px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    color: #585757;
    text-align: left;
}

.vingHp20 {
    font-family: Raleway;
    font-size: 19px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    color: #585757;
    text-align: left;
}

@media only screen and (max-width: 600px) {
    #row2{
        background:url("https://cdn.zeplin.io/6061ed0fe3392716f0cc504a/assets/84F0AC60-6CDE-4C00-890D-8394A37D8FE9.svg") !important;
        background-size: 100vw !important;
        background-repeat:no-repeat !important;
        margin-top:30px !important;
        padding-top:0px !important;
    }
    #ving {
        background-size: 135vh;  
    }
    #vingMainH {
        font-size: 24px !important;
        width: 85% !important;
    }
    #vingLogo {
        width: 125px !important;
        margin-top: 40px !important;
    }
    #orgImg {
        width: 95% !important;
    }
    .vingM {
        padding-left: 20px;
        padding-Right: 20px;   
        background-position-y: -80vh;     
    }
    .noBackgroundInMobile {
        background: transparent !important; 
    }
     
    .showInDesktop {
        display: none !important;
    }
    .showInMobile {
        display: block !important;
    }
}
