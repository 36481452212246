body {
  margin: 0;   
  background-color:white !important;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: beige;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: grey;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: black;
}

 
 

#footer {  
  padding-top: 12vh; 
  color: #585757;
  bottom: 0px;  
}

#footer_white {   
  background-repeat: no-repeat !important;
  background-position: left top !important;
  background-size: cover !important;
  background-size: 140vw !important;
  background-position-x: -100px !important; 
  padding-top: 12vh; 
  color: #585757;
  bottom: 0px ; 
}
 
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media only screen and (max-width: 600px) {
  #footer_left {
    text-align: center !important;
  }
  #footer_right {
    text-align: center !important;
  }
  .footer {
    background-size: auto !important;
  }
 }

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#navbar {
   padding-top: 15px; 
   padding-bottom: 10px; 
   margin: 0px;
   position: fixed;
   top: 0;
   width: 100vw;
   z-index: 999;
   height: auto;
}

@media only screen and (max-width: 880px) {
#logo {
   width: 51px !important;
}
}
#home {   
    height:95vh;
    padding-left:20px;
    padding-right:20px;
    padding-top: 33vh;
    padding-bottom:35vh;
    font-family: Palatino-Roman; 
    color: #796b6d; 
    background-image: url('https://cdn.zeplin.io/6061ed0fe3392716f0cc504a/assets/BAA6C00D-05CF-4C28-8831-59E04D89969B.svg');
    background-position: center bottom;
    background-size: 120vw, 100vh; 
    background-repeat: no-repeat;  
}

#homeAbout {
    color: #796b6d;
    font-family: RalewayRoman;
}


@media only screen and (max-width: 880px) {
    #home {    
    background-position: center top;
    background-size: 160vh, 100vh; 
    background-position-y: 0vh;
    }
    .home-title {
        font-size: 24px !important;
        margin-bottom: 24px !important;
        font-family: serif !important;
    }
    #home-about {
        font-size: 18px !important;
    }
}
#projectsContainer {
    margin-top: 50px;
}

.projectCard :hover {
    cursor: pointer;
}

@media only screen and (max-width: 880px) {
    .projectCardImg {
        width: 90% !important;
    }
  }
#about {
    padding-top:25vh;
    padding-bottom: 20vh; 
    /* background-image: url('https://cdn.zeplin.io/6061ed0fe3392716f0cc504a/assets/033864E6-A360-43CB-AB78-1D5046C99414.svg'); */
    background-position: center bottom;
    background-size: 400vw; 
    background-repeat: no-repeat;   
    background-color: #f6ede7;  
}

@media only screen and (max-width: 1000px) {
   #profilePicture {
       width: 65% !important;  
   }
   .showInDesktop{
       display: none !important;
   }
   .showInMobile{
    display: block !important;
}
  }
 
#rapido { 
    padding-top: 25vh;
    background-image: url('https://cdn.zeplin.io/6061ed0fe3392716f0cc504a/assets/3ED0F65C-8235-4544-9C9A-FE45D427E6F0.svg');
    background-position: left top;
    background-size: cover; 
    background-size: 105vw; 
    background-repeat: no-repeat;   
    background-position-y: -210px;
}

.rapidoH {
    font-family: serif;
    font-size: 36px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: normal;
    color: #15204e; 
}

.rapidoHp {
    font-family: Raleway;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    color: #585757;
    text-align: left;
}

  

@media only screen and (max-width: 600px) {

    #rapido-logo {
        width: 110px !important;
    }
    #rapidoMainH {
        font-size: 24px !important;
        font-family:serif !important;
    }
    #rapido {    
        background-size: 135vh;      
    }
    .rapidoM {
      padding-left: 20px;
      padding-Right: 20px;      
  }
    #bnwimg {
        margin-left: auto !important;
        margin-top: 30px !important;
        width:85% !important;
    }
    
  .showOnMobile {
    display: block !important;
  }
  .showOnDesktop {
    display: none;
  }
   }

   .ulRapido {
    list-style: none; /* Remove default bullets */
  }
  
  .ulRapido li::before {
    content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
    color: #f6d70f !important; /* Change the color */
    font-weight: bold; /* If you want it to be bold */
    display: inline-block; /* Needed to add space between the bullet and the text */
    width: 10px; /* Also needed for space (tweak if needed) */
    margin-left: -1em; /* Also needed for space (tweak if needed) */
    font-size:23px;
  }
#innaz { 
    padding-top: 25vh;
    background-image: url('https://cdn.zeplin.io/6061ed0fe3392716f0cc504a/assets/D6BDFEF7-197E-4AB4-8074-E9BADEC94602.svg');
    background-position: left top;
    background-size: cover; 
    background-size: 105vw; 
    background-repeat: no-repeat;   
    background-position-y: -110px;
}


@media only screen and (max-width: 600px) {
    #innaz {
        padding-left: 20px;
        padding-Right: 20px;
        background-size: 135vh;     
    }
    #innazMainH {
        font-size: 24px;
    }
}
#aid { 
    padding-top: 25vh;
    background-image: url('https://cdn.zeplin.io/6061ed0fe3392716f0cc504a/assets/9FB09D9D-CCEC-49CD-82F9-D394BE5DC7A4.svg');
    background-position: left top;
    background-size: cover; 
    background-size: 115vw; 
    background-repeat: no-repeat;   
    background-position-y: -140px;
    background-position-x: -50px;     

}

.aidH {
    font-family: serif;
    font-size: 36px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: normal;
    color: #15204e; 
    margin-bottom:30px;
}

.aidHp {
    font-family: Raleway;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    color: #585757;
    text-align: left;
}

@media only screen and (max-width: 600px) {
    #aidMainH {
        font-size: 24px !important;
    }
    .aidM {
        padding-left: 20px;
        padding-Right: 20px;  
    } 
    #aid {
        background-size: 115vh;     
    } 
    .showOnDesktop {
        display: none;
      }
}

ul {
    list-style: none; /* Remove default bullets */
  }
  
  ul li::before {
    content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
    color: #cd2046; /* Change the color */
    font-weight: bold; /* If you want it to be bold */
    display: inline-block; /* Needed to add space between the bullet and the text */
    width: 1em; /* Also needed for space (tweak if needed) */
    margin-left: -1em; /* Also needed for space (tweak if needed) */
  }
#ving { 
    padding-top: 25vh;
    background-image: url('https://cdn.zeplin.io/6061ed0fe3392716f0cc504a/assets/79E13BAF-494A-45FA-B75F-A1B739A8603F.svg'),url("https://cdn.zeplin.io/6061ed0fe3392716f0cc504a/assets/12C3131B-EE0F-4746-8EEC-384E70428488.svg");
    background-position: left top;
    background-size: cover; 
    background-size: 105vw; 
    background-repeat: no-repeat;   
    background-position-y: -110px;
}

.vingH {
    font-family: serif;
    font-size: 36px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: normal;
    color: #15204e;  
}

.vingHp {
    font-family: Raleway;
    font-size: 19px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    color: #585757;
    text-align: left;
}

.vingHp20 {
    font-family: Raleway;
    font-size: 19px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    color: #585757;
    text-align: left;
}

@media only screen and (max-width: 600px) {
    #row2{
        background:url("https://cdn.zeplin.io/6061ed0fe3392716f0cc504a/assets/84F0AC60-6CDE-4C00-890D-8394A37D8FE9.svg") !important;
        background-size: 100vw !important;
        background-repeat:no-repeat !important;
        margin-top:30px !important;
        padding-top:0px !important;
    }
    #ving {
        background-size: 135vh;  
    }
    #vingMainH {
        font-size: 24px !important;
        width: 85% !important;
    }
    #vingLogo {
        width: 125px !important;
        margin-top: 40px !important;
    }
    #orgImg {
        width: 95% !important;
    }
    .vingM {
        padding-left: 20px;
        padding-Right: 20px;   
        background-position-y: -80vh;     
    }
    .noBackgroundInMobile {
        background: transparent !important; 
    }
     
    .showInDesktop {
        display: none !important;
    }
    .showInMobile {
        display: block !important;
    }
}

#selfi { 
    padding-top: 25vh;
    background-image: url("https://cdn.zeplin.io/6061ed0fe3392716f0cc504a/assets/56F3088F-0DA6-4C2E-9881-56AEB824A9D4.svg");
    background-position: left top;
    background-size: cover; 
    background-size: 110vw; 
    background-repeat: no-repeat;   
    background-position-y: -250px;     
    background-position-x: -50px;     
}

.selfiH {
    font-family: serif;
    font-size: 36px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: normal;
    color: #15204e; 
    text-align: center;
}

.selfiHp {
    font-family: Raleway;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    color: #585757;
    text-align: left;
}

@media only screen and (max-width: 800px) {
    #aidLogoI {
        width: 110px !important;
    }
    #selfi {     
        background-size: 145vh; 
    }
    .selfiM {
        padding-left: 20px;
        padding-Right: 20px;   
        background-position-y: -80vh;     
    }
    .noBackgroundInMobile {
        background: transparent !important; 
    }  
    .showInDesktop {
        display: none !important;
    }
    .showInMobile {
        display: block !important;
    }
}

.ulSelfi {
    list-style: none; /* Remove default bullets */
  }
  
  .ulSelfi li::before {
    content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
    color: #cd2046 !important; /* Change the color */
    font-weight: bold; /* If you want it to be bold */
    display: inline-block; /* Needed to add space between the bullet and the text */
    width: 1em; /* Also needed for space (tweak if needed) */
    margin-left: 0px; /* Also needed for space (tweak if needed) */
  }

