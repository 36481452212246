#selfi { 
    padding-top: 25vh;
    background-image: url("https://cdn.zeplin.io/6061ed0fe3392716f0cc504a/assets/56F3088F-0DA6-4C2E-9881-56AEB824A9D4.svg");
    background-position: left top;
    background-size: cover; 
    background-size: 110vw; 
    background-repeat: no-repeat;   
    background-position-y: -250px;     
    background-position-x: -50px;     
}

.selfiH {
    font-family: serif;
    font-size: 36px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: normal;
    color: #15204e; 
    text-align: center;
}

.selfiHp {
    font-family: Raleway;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    color: #585757;
    text-align: left;
}

@media only screen and (max-width: 800px) {
    #aidLogoI {
        width: 110px !important;
    }
    #selfi {     
        background-size: 145vh; 
    }
    .selfiM {
        padding-left: 20px;
        padding-Right: 20px;   
        background-position-y: -80vh;     
    }
    .noBackgroundInMobile {
        background: transparent !important; 
    }  
    .showInDesktop {
        display: none !important;
    }
    .showInMobile {
        display: block !important;
    }
}

.ulSelfi {
    list-style: none; /* Remove default bullets */
  }
  
  .ulSelfi li::before {
    content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
    color: #cd2046 !important; /* Change the color */
    font-weight: bold; /* If you want it to be bold */
    display: inline-block; /* Needed to add space between the bullet and the text */
    width: 1em; /* Also needed for space (tweak if needed) */
    margin-left: 0px; /* Also needed for space (tweak if needed) */
  }